export const TEXT = {
  Bio: 'I am a Software Engineer passionate about bringing designs to life, that thrives on solving problems, squashing bugs, and continuously refining my process. I love expressing creativity through code that not only looks great but also performs well.',
  B2: 'I started my development journey primarily focused on web front-ends, dabbling in different backend languages, and occasionally deploying systems in AWS. Currently I am focusing heavily on mobile apps, and also spending some time learning golang.',
  B3: 'In addition to writing code I enjoy talking about code, ways to be a better engineer, teaching, and leading projects with awesome teammates.',
  Home: 'Home',
  About: 'About',
  Experience: 'Experience',
  Projects: 'Projects',
  SkillsTitle: 'Skills & Experience',
  Resume: 'Resume',
  CodingExperience: '// 6+ years of professional coding experience',
  TechnicalSkills: 'Technical Skills',
  Jobs: 'Jobs',
};

export const mainSkills = [
  'Mobile Development',
  'iOS Development',
  'Android Development',
  'Web Development',
  'Test Driven Development',
  'API\'s',
  'AWS'
];

export const frameworks = [
  'React',
  'React Native',
  'React Query',
  'Jest',
  'React Testing Library',
  'Tailwind',
  'Styled Components',
  'Next.js',
];

export const languages = [
  'Typescript',
  'Javascript',
  'CSS',
  'HTML',
  'Node.js',
  'Express'
];

export const jobInfo = [
  {
    role: 'Shipt | Remote • Software Engineer',
    dates: 'February 2022 - Present',
    summary: 'I currently work at Shipt where I help build and maintain a grocery delivery app using react-native and typescript.'
  },
  {
    role: 'Element 84 | Alexandria, VA / Remote • Junior Front-End Developer',
    dates: 'February 2019 - February 2022',
    summary: 'While focusing primarily on the front-end I helped build a map based component library, worked on map based websites focused on sattellite data. While working on the backend I helped write a geo-spatial processing pipeline using python, as well as several projects written using node.js and ruby on rails.'
  },
  {
    role: 'George Washington University | Remote • Web Developer Teachers Assistant',
    dates: 'July 2018 - February 2019',
    summary: 'I was a teachers assistant at a 6 month full-stack coding bootcamp at George Washington University. Students with little to perhaps no experience were taught coding languages, concepts, and most importantly how to teach themselves in preperation for a career in web development'
  }
];

