import { styled } from 'styled-components';
import MarquetDefault from '../images/marquet_bw.jpg';
import MarquetHover from '../images/marquet_color.jpg';
import { TEXT } from '../text';

const Container = styled.div.attrs({ className: 'flex items-center justify-center h-screen w-3/4 mx-auto' })``;
const AboutMeText = styled.p.attrs({ className: 'mr-4 text-lg' })``;
const AboutMeTextContainer = styled.div.attrs({ className: 'flex flex-col gap-y-4' })``;
const AboutMeTitle = styled.p.attrs({ className: 'text-center font-bold text-5xl' })``;
const PortraitWrapper = styled.div.attrs({ className: 'relative w-3/4 h-1/2 group rounded-3xl overflow-hidden' })``;

const DefaultPortrait = styled.img.attrs({
  className: 'w-full h-full object-cover absolute inset-0 rounded-3xl transition-opacity duration-300 ease-in-out opacity-100 group-hover:opacity-0'
})``;

const HoverPortrait = styled.img.attrs({
  className: 'w-full h-full object-cover absolute inset-0 rounded-3xl transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100'
})``;
export function About() {
  return (
    <Container id="about">
      <AboutMeTextContainer>
        <AboutMeTitle>{TEXT.About}</AboutMeTitle>
        <AboutMeText>{TEXT.Bio}</AboutMeText>
        <AboutMeText>{TEXT.B2}</AboutMeText>
        <AboutMeText>{TEXT.B3}</AboutMeText>
      </AboutMeTextContainer>
      <PortraitWrapper>
        <DefaultPortrait src={MarquetDefault} alt='marquet' />
        <HoverPortrait src={MarquetHover} alt='marquet' />
      </PortraitWrapper>
    </Container>
  );
};

