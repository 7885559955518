import {styled} from 'styled-components';
import { useContext } from 'react';
import { ThemeContext } from '../store/ThemeProvider';
import { MoonIcon, SunIcon } from '@heroicons/react/24/solid'

// const ThemeButton = styled.button.attrs({ className: 'fixed left-o bottom-0' })``
const StyledLightModeIcon = styled(MoonIcon).attrs({ className: 'w-8 h-8 p-1 rounded-3xl border-2 border-current object-cover' })``;
const StyledDarkModeIcon = styled(SunIcon).attrs({ className: 'w-8 h-8 p-1 rounded-3xl border-2 border-current object-cover' })``;

export function ToggleButton() {
  const { toggleTheme, isDarkMode } = useContext(ThemeContext);

  return (
    <button
      onClick={toggleTheme}
      className="fixed left-o bottom-1 px-4 py-2 rounded-3xl transition-colors duration-300"
    >
      {isDarkMode ? <StyledLightModeIcon /> : <StyledDarkModeIcon />}
    </button>
  );
};
