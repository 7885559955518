// TODO: Create a function that turns svg's into react components
import { ReactComponent as AppleLogo } from '../images/apple_logo.svg';
import { ReactComponent as AndroidLogo } from '../images/android_logo.svg';
import { ReactComponent as AWSLogo } from '../images/aws_logo.svg';
import { ReactComponent as CSSLogo } from '../images/css_logo.svg';
import { ReactComponent as Download } from '../images/download.svg';
import { ReactComponent as DownArrow } from '../images/down_arrow.svg';
import { ReactComponent as ExpressLogo } from '../images/express_logo.svg';
import { ReactComponent as GithubLogo } from '../images/github_logo.svg';
import { ReactComponent as GoLogo } from '../images/go_logo.svg';
import { ReactComponent as HTMLLogo } from '../images/html_logo.svg';
import { ReactComponent as JavascriptLogo } from '../images/javascript_logo.svg';
import { ReactComponent as JestLogo } from '../images/jest_logo.svg';
import { ReactComponent as LinkedInLogo } from '../images/linkedin_logo.svg';
import { ReactComponent as NextLogo } from '../images/next_logo.svg';
import { ReactComponent as NodeLogo } from '../images/node_logo.svg';
import { ReactComponent as PostgreSQLLogo } from '../images/postgresql_logo.svg';
import { ReactComponent as PostmanLogo } from '../images/postman_icon.svg';
import { ReactComponent as ReactLogo } from '../images/react_logo.svg';
import { ReactComponent as ReactQueryLogo } from '../images/react_query_logo.svg';
import { ReactComponent as ReactTestingLibraryLogo } from '../images/react_testing_library_logo.svg';
import { ReactComponent as StyledComponentsLogo } from '../images/styled_components_logo.svg';
import { ReactComponent as TailwindLogo } from '../images/tailwind_logo.svg';
import { ReactComponent as TypescriptLogo } from '../images/typescript_logo.svg';

export const icons = {
  download: { name: 'Download', image: Download },
  downArrow: { name: 'DownArrow', image: DownArrow },
  github: {name: 'Github', image: GithubLogo },
  linkedIn: { name: 'LinkedIn', image: LinkedInLogo },
};

export const skillsLogos = [
  { name: 'Android', image: AndroidLogo, color: '#3DDC84' }, 
  { name: 'Apple', image: AppleLogo, color: '#A2AAAD'}, 
  { name: 'AWS', image: AWSLogo, color: '#FF9900'}, 
  { name: 'React', image: ReactLogo, color: '#61DAFB'}, 
  { name: 'CSS', image: CSSLogo, color: '#264de4'}, 
  { name: 'Express', image: ExpressLogo, color: 'grey'}, 
  { name: 'HTML', image: HTMLLogo, color: '	#e34c26' }, 
  { name: 'Javascript', image: JavascriptLogo, color: '#F7DF1E' }, 
  { name: 'Jest', image: JestLogo, color: 'red' }, 
  { name: 'Next', image: NextLogo, color: 'grey' }, 
  { name: 'Node', image: NodeLogo, color: '#68A063' }, 
  { name: 'Go', image: GoLogo, color: '#06b6d4' }, 
  { name: 'PostgreSQL', image: PostgreSQLLogo, color: '#008bb9' }, 
  { name: 'Postman', image: PostmanLogo, color: '#EF5B25'}, 
  { name: 'RTL', image: ReactTestingLibraryLogo, color: 'red'}, 
  { name: 'Styled Components', image: StyledComponentsLogo, color: '#F385A4'}, 
  { name: 'Tailwind', image: TailwindLogo, color: '#06b6d4' }, 
  { name: 'Typescript', image: TypescriptLogo, color: '#007acc' }, 
  { name: 'React Query', image: ReactQueryLogo, color: '#61DAFB'}, 
];
