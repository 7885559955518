import { Navbar } from './components/Navbar';
import { ToggleButton } from './components/ToggleButton';
import { Home } from './components/Home';
import { About } from './components/About';
import { Experience } from './components/Experience';
// import { Portfolio } from './components/Portfolio';

import './tailwind.css';
import './App.css';
import { ThemeContextProvider } from './store/ThemeProvider';
// <Portfolio />
function App() {
  return (
    <ThemeContextProvider>
      <header className="App-header">
        <Navbar />
        <ToggleButton />
        <Home />
        <About />
        <Experience />
      </header>
    </ThemeContextProvider>
  );
}

export default App;
