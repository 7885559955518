import styled, { keyframes, css } from 'styled-components';
import Logo from '../images/marquet_reid_logo.png';
import { ThemeContextProvider } from '../store/ThemeProvider';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

const rotateLeft = keyframes`
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
`;


const RotateLeftImg = styled.img<{ $animate: boolean }>`
  ${({ $animate }) =>
    $animate &&
    css`
    animation: ${rotateLeft} 2s;
  `}
`;

const Container = styled.div.attrs({ className: 'flex flex-col items-center justify-center h-screen' })``;
const MarquetReidLogo = styled(RotateLeftImg).attrs({ className: 'max-w-1/2 max-h-1/2 object-contain mt-10 cursor-pointer' })``;
const NameText = styled.p.attrs({ className: 'mt-4 font-cursive text-7xl' })``;
const TitleText = styled.p.attrs({ className: 'text-4xl mt-2' })``;
const DownButton = styled(ChevronDownIcon).attrs({ className: 'w-8 h-8 rounded-3xl object-cover border-2 border-current mt-8 p-1 stroke-current' })``;

export function Home() {
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    setIsAnimating(true); 

    // Reset the animation state at the same time as the animation ending
    setTimeout(() => setIsAnimating(false), 2000);
  };

  return (
    <ThemeContextProvider>
      <Container id="home">
        <MarquetReidLogo src={Logo} alt="logo" $animate={isAnimating} onClick={handleClick}/>
        <NameText>{'Marquet Reid'}</NameText>
        <TitleText>{'Software Engineer'}</TitleText>
        <a href="#about">
          <DownButton />
        </a>
      </Container>
    </ThemeContextProvider>
  );
};
