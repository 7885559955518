import { createContext, useState } from 'react';

// Create context with a default value
export const ThemeContext = createContext({
  isDarkMode: false,
  toggleTheme: () => {}
});

export const ThemeContextProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);

    if (isDarkMode) {
      document.querySelector('.App-header').style.backgroundColor = '#fff'
      document.querySelector('.App-header').style.color = '#000'
    } else {
      document.querySelector('.App-header').style.backgroundColor = '#000'
      document.querySelector('.App-header').style.color = '#fff'
    }
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};